html,
body {
  font-family: "Karla", sans-serif;
  height: 100%;
  background-color: #f6f6f6;
  font-size: 0.98rem;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  overflow: hidden;
}

.App {
  text-align: center;
  height: 100%;
}

.loader {
  transition: 0.1s ease-in;
  position: fixed;
  z-index: 999999999;
  height: 100%;
  width: 100%;
  overflow: show;
  margin: auto;
  padding-top: 5%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  visibility: hidden;
  opacity: 0;
}

.loader.show {
  visibility: visible;
  opacity: 1;
}

.valk-logo {
  height: 61px;
  position: absolute;
  left: 20px;
  top: 15px;
  /*  
  width: 200px;
  position: absolute;
  bottom: 0;
  left: 32px;
  */
}

.App-header {
  height: 5em;
  border-bottom: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
}
.w50 {
  width: 50px;
}
.btn {
  border-radius: 2px;
}

.btn-primary {
  background-color: #ff6d0c;
  border-color: #e1e1e1;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #999;
  background-color: #eee;
  border-color: #ddd;
}

.loginmodal-container .btn-primary {
  width: 100%;
  height: 40px;
}

.input-search {
  border-radius: 2px !important;
  border: 0 none !important;
  padding-left: 40px;
  padding-right: 15px;
}
.searchicon {
  position: relative;
  /*max-width: 500px;*/
}
.searchicon .icon {
  position: absolute;
  left: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 32px;
  height: 100%;
  line-height: normal;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
}
.col-search .searchicon .icon {
  pointer-events: none;
}
.col-search.expand .searchicon .icon {
  pointer-events: all;
}
.searchicon .icon .fa-search {
  color: #ff6d0c;
}
.searchicon .icon .fa-times:before {
  color: #ff6d0c;
  content: "\F002";
}

.App-header div.input-group {
  margin-top: 12px;
  margin-right: 1em;
  width: 50%;
}

.input-group-addon {
  margin-left: -36px;
  margin-top: 3px;
  z-index: 3;
  border-radius: 2px;
  background-color: transparent;
  border: none;
}

#search-btn {
  border-radius: 2px;
  margin-right: 16px;
}

.loginmodal-container {
  padding: 30px;
  max-width: 350px;
  margin: 0 auto;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0px 1px 4px 1px #eaeaea;
  padding-top: 2rem;
  margin-top: 0rem;
}

.text-orange {
  color: #ff6d0c !important;
}

.sidebar-left {
  transition: left 0.1s ease-in;
  width: 290px;
  background-color: #fff;
  position: fixed;
  top: 0px;
  bottom: 0;
  left: -290px;
  z-index: 10;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  /*border-right: 1px solid #c1c3c6;*/
}

.sidebar-left .sidebar-logo {
  height: calc(6em + 1px);
  position: relative;
  border-bottom: 1px solid #fff;
  background: #000;
}

.sidebar-left .sidebar-logo + div {
  border-right: 1px solid #c1c3c6;
}

.sidebar-left .nav-item {
  border-bottom: 1px solid #c1c3c6;
  border-left: 3px solid #fff;
  height: 4em;
  line-height: 4em;
  color: #000;
}

.sidebar-left .nav-item.active {
  /*border-left: 3px solid #0d6fd7;*/
  color: #ff6d0c;
  border-left: 3px solid #ff6d0c;
}
.sidebar-left .nav-item:hover {
  border-left: 3px solid #ff6d0c;
  color: #ff6d0c;
  text-decoration: none;
}

.sidebar-left .nav-item .fal {
  font-size: 24px;
  margin-right: 10px;
  width: 30px;
}
.sidebar-right {
  transition: right 0.1s ease-in-out;
  width: 100vw;
  max-width: 360px;
  /*width: 240px;*/
  background-color: #fff;
  position: fixed;
  top: 0px;
  bottom: 0;
  /*right: -240px;*/
  right: -360px;
  z-index: 1000;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  border-left: 1px solid #c1c3c6;
}

.sidebar-right.active {
  right: 0;
}

.main {
  transition: padding-left 0.1s ease-in;
  transition: padding-right 0.1s ease-in;
  padding-left: 0; /* 180 + 40 */
  padding-right: 0;
  max-height: 100%;
}

.main.cart {
  /*padding-right: 255px; /* 180 + 40 */
  padding-right: 360px; /* 180 + 40 */
}

.topbar {
  height: calc(6em + 1px);
  line-height: 6em;
  border-bottom: 1px solid #fff;
  display: flex;
  background-color: #000;
}
.border-r {
  border-right: 1px solid #c1c3c6 !important;
}
.col-search {
  /*padding-right: 30px;*/
  display: table-cell;
  width: calc(100% - 80px);
  flex-grow: 10;
}
.col-cart {
  white-space: nowrap;
  display: flex;
  top: 0px;
  right: 0;
  height: 100%;
  /*line-height: 58px;*/
}

.btn-cart {
  /*border-radius: 32px;
  width: 54px;
  height: 54px;*/
  border-radius: 0px;
  /*height: calc(100% + 2px);*/
  margin-top: -2px;
  width: 80px;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.btn-cart:disabled {
  background-color: initial;
  border-left: none;
}

.btn-cart:hover {
  background-color: #ededed;
  /*border-right: 1px solid #c1c3c6;
  border-top: 1px solid #c1c3c6;
  border-bottom: 1px solid #c1c3c6;*/
}

.btn-cart .fal {
  font-size: 24px;
  padding-top: 5px;
  color: #fff;
}

.btn-cart .fal.fa-list {
  color: #fff;
}

.btn-cart .badge {
  margin-left: -10px;
  top: -18px;
  background-color: #ff6d0c !important;
  color: white;
}
.btn-cart:hover .badge {
  box-shadow: 0 0px 0px 1px #fff;
}
.mobileMenuToggle .far {
  font-size: 24px;
  padding-top: 5px;
  color: #fff;
}

.border-gray {
  border-color: #c1c3c6 !important;
}
.carthover:hover {
  background-color: #ff6d0c;
  /*border-left: 1px solid #c1c3c6;*/
}
/*
.carthover:hover > .fas {
}
*/
/*
.carthover:hover > .badge {
}
*/
.menuhover {
  height: calc(100% + 2px);
  display: block;
}
.menuhover:hover {
  background-color: #ff6d0c;
  border-right: 1px solid transparent !important;
}
/*
.menuhover:hover > .fas {
}
*/
.btn.menuhover:focus {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent !important;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  border-color: #c1c3c6;
}
.dropdown-menu {
  line-height: 32px;
}

.bc-bar {
  height: 3em;
  line-height: 3em;
  text-align: left;
  color: #555;
  font-size: 0.9em;
  border-bottom: 1px solid #c1c3c6;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bc-bar a {
  color: #555;
}

.bc-bar a:hover {
  color: #ff6d0c;
}

.bc-bar .fa-home {
  font-size: 1.4em;
  vertical-align: middle;
}

.bc-bar .fa-arrow-right {
  font-size: 0.9em;
  vertical-align: middle;
  margin: 0 1.5em;
}

.content {
  /*height: calc(100% - 96px) !important;*/
  height: calc(100% - 6.1em) !important;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.content-bc {
  height: calc(100% - 136px) !important;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}
.content-checkout {
  height: calc(100% - 200px) !important;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}
.maxw1200 {
  max-width: 1200px;
}

.content-block {
  background-color: white;
  border-radius: 2px;
  box-shadow: 0px 1px 4px 1px #dedede;
}

.checkout-footer {
  /*background-color: #fff;*/
  height: 64px;
  border-top: 1px solid #c1c3c6;
}

.content h2 {
  color: #939598;
  font-size: 1.3em;
  text-align: left;
  font-weight: bold;
  margin-bottom: 1em;
}

.notifications {
  animation: slide-up 0.4s ease;
  border-left: 3px solid #ff6d0c;
}

.delay-notifications {
  animation: slide-up 0.4s ease;
  border-left: 3px solid #0c59ff;
}
.notification,
.delay-notification {
  transition: 0.1s ease-in;
  line-height: 40px;
  max-height: 41px;
  border-bottom: 1px solid #dedede;
  clear: both;
  overflow: hidden;
  -webkit-transition: opacity 0.2s ease-out, max-height 0.2s ease 0.2s;
  -moz-transition: opacity 0.2s ease-out, max-height 0.2s ease 0.2s;
  -o-transition: opacity 0.2s ease-out, max-height 0.2s ease 0.2s;
  transition: opacity 0.2s ease-out, max-height 0.2s ease 0.2s;
}

.notification.removed,
.notification.open.removed {
  max-height: 0;
  opacity: 0;
}

.notification.open {
  max-height: 401px;
}

.notification:hover,
.delay-notification:hover {
  background-color: #f7fbff;
}

.notification .fas {
  color: #ff6d0c;
  float: left;
  line-height: 40px;
  margin: 0 1em;
}
.delay-notification .fas {
  color: #0c59ff;
  float: left;
  line-height: 40px;
  margin: 0 1em;
}

.notification label,
.delay-notification label {
  float: left;
  text-align: left;
  font-size: 0.9em;
  line-height: 40px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 120px);
  text-overflow: ellipsis;
  cursor: pointer;
}
.notification.open label {
  white-space: normal;
  cursor: initial;
}
a.linkshome {
  font-size: 0.9em;
  color: #0d6fd7;
  background-color: #fff;
  border-radius: 50px;
  padding: 5px 15px;
}

.product {
  background-color: #fff;
  transition: 0.1s ease-in;
  line-height: 64px;
  border-bottom: 1px solid #dedede;
  text-align: left;
  clear: both;
  border-left: 3px solid transparent;
  position: relative;
  overflow: hidden;
}
.dragable .product {
  transition: none;
  transition: background 0.1s ease-in;
  transition: border 0.1s ease-in;
}
.product a {
  color: #111;
}

.product:hover {
  background-color: #f7fbff;
  border-left: 3px solid #0d6fd7;
}

.product .phototitle {
  float: left;
}

.product .cartprice {
  float: right;

  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin-top: 16px;
  margin-bottom: 16px;
}

.product .photo {
  float: left;
  width: 52px;
  height: 56px;
  text-align: center;
}

.product .image_placeholder {
  width: 52px;
  height: 46px;
  margin-top: 8px;
  display: block;
  background-color: #f6f6f6;
}

.product .photo .far {
  font-size: 32px;
  color: #e1e1e1;
}

.product .title {
  float: left;
  max-width: calc(100% - 52px);
  line-height: 1.5em;
  margin-top: 9px;
}

.product .title strong em {
  background-color: #ffffb3;
  font-style: normal;
}
.product .price {
  float: right;
  width: 100px;
  text-align: right;
  line-height: normal;
}
.product .unit {
  float: right;
  font-size: 0.9em;
  text-align: center;
  text-transform: uppercase;
  line-height: normal;
  border-radius: 64px;
  border: 1px solid #ccc;
  padding: 6px;
  margin: 0 7px;
  width: 100px;
}

.cartprice .unit select,
.product .unit select {
  border: 0px none;
  background-color: transparent;
  width: 100%;
  outline: none;
}

.cartprice .unit span,
.product .unit span {
  background-color: #f1f1f1;
  padding: 5px 10px;
  border-radius: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product .cart-buttons {
  float: right;
  position: relative;
}
.product .cart-buttons .actionButtons{
min-width: 180px;
float:left;
}
.product .productweight {
  width: 384px;
  height: calc(100% + 32px);
  position: absolute;
  right: -409px;
  top: -16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f6f6f6;
  border-left: 3px solid #007bff;
  /* color: #fff; */
  transition: all 0.1s ease;
  padding-right: calc(0.5rem + 16px);
  padding-left: 0;
}
.product .productweight.show {
  right: -24px;
}
.product .productweight input[type="number"] {
  width: 100%;
  line-height: normal;
  border-radius: 6px;
  border: 1px solid #f1f1f1;
  text-align: right;
}
/*
.weightinput {
   display: flex;
  flex-direction: column;
  line-height: normal;
  padding: 0 10px;
  text-align: center;
  max-width: calc(50% - 1rem);
}
*/
.weightinput input {
  width: 78px;
  margin-left: 1rem;
  margin-right: 1rem;
}
/*
.weightinput span {
  padding: 6px;
}
*/
.product .heightfix {
  height: 64px;
}
.product .weighttable {
  line-height: normal;
}

a.a-check {
  float: left;
  transition: 0.1s ease-in;
  border-radius: 32px;
  border: 1px solid #ff6d0c;
  background-color: #fff;
  color: #ff6d0c;
  width: 32px;
  height: 32px;
  line-height: 27px;
  /*margin-top: 16px;*/
  font-size: 16px;
  padding: 0;
  padding-top: 2px;
  text-decoration: none;
  display: block;
  text-align: center;
  cursor: pointer;
}

a.a-plus {
  float: left;
  transition: 0.1s ease-in;
  border-radius: 32px;
  border: 1px solid #111;
  background-color: #111;
  color: #fff;
  width: 32px;
  height: 32px;
  line-height: 28px;
  /*margin-top: 16px;*/
  font-size: 20px;
  padding: 0;
  text-decoration: none;
  display: block;
  text-align: center;
}

a.a-min {
  float: left;
  transition: 0.1s ease-in;
  border-radius: 32px;
  border: 1px solid #ddd;
  background-color: #fff;
  color: #ddd;
  width: 32px;
  height: 32px;
  line-height: 30px;
  /*margin-top: 16px;*/
  font-size: 20px;
  padding: 0;
  text-decoration: none;
  display: block;
  text-align: center;
  cursor: default;
}

a.a-min.active {
  border: 1px solid #111;
  background-color: #111;
  color: #fff;
  cursor: pointer;
}

a.a-plus.active {
  border: 1px solid #ff6d0c;
  background-color: #ff6d0c;
}

a.a-min.active:hover {
  border: 1px solid #111;
  background-color: #111;
  color: #fff;
}

a.a-plus:hover {
  border: 1px solid #ff6d0c;
  background-color: #ff6d0c;
}

input.input-cart {
  background-color: transparent;
  float: left;
  margin: 0;
  height: 32px;
  /*margin-top: 16px;*/
  border-radius: 32px;
  border: none;
  width: 3em;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  outline: none;
  color: #aaa;
  line-height: normal;
}

input.input-cart.active {
  color: #111;
}

input.input-cart::placeholder {
  color: #aaa;
}

div.a-weight {
  float: left;
  margin: 0 0.6em;
  transition: 0.1s ease-in;
  border-radius: 32px;
  border: 1px solid lightcoral;
  background-color: #fff;
  color: lightcoral;
  width: 48px;
  height: 32px;
  line-height: 27px;
  /*margin-top: 16px;*/
  font-size: 16px;
  padding: 0;
  padding-top: 2px;
  text-decoration: none;
  display: block;
  text-align: center;
  cursor: pointer;
}
div.a-weight:hover {
  border: 1px solid lightcoral;
  background-color: lightcoral;
  color: #ffffff;
}
div.a-weight.disabled {
  border: 1px solid #ddd;
  color: #ddd;
  pointer-events: none;
}
div.a-list {
  float: left;
  transition: 0.1s ease-in;
  border-radius: 32px;
  border: 1px solid #007bff;
  background-color: #fff;
  color: #007bff;
  width: 48px;
  height: 32px;
  line-height: 27px;
  /*margin-top: 16px;*/
  font-size: 16px;
  padding: 0;
  padding-top: 2px;
  text-decoration: none;
  display: block;
  text-align: center;
  cursor: pointer;
}
div.a-list:hover {
  border: 1px solid #007bff;
  background-color: #007bff;
  color: #fff;
}
.a-remove {
  float: left;
  transition: 0.1s ease-in;
  border-radius: 32px;
  background-color: #f4f4f4;
  color: #777 !important;
  width: 48px;
  height: 32px;
  line-height: 27px;
  font-size: 16px;
  padding: 0;
  padding-top: 2px;
  text-decoration: none;
  display: block;
  text-align: center;
  cursor: pointer;
}
.a-remove:hover {
  background-color: #666;
  color: #fff !important;
}
.a-comment {
  float: left;
  transition: 0.1s ease-in;
  border-radius: 32px;
  background-color: #f4f4f4;
  color: #777 !important;
  width: 48px;
  height: 32px;
  line-height: 27px;
  font-size: 16px;
  padding: 0;
  padding-top: 2px;
  text-decoration: none;
  display: block;
  text-align: center;
  cursor: pointer;
  margin-right: 5px;
}
.a-comment:hover {
  background-color: #666;
  color: #fff !important;
}
.a-quote {
  float: left;
  transition: 0.1s ease-in;
  border-radius: 32px;
  color: #fff !important;
  border: 1px solid #111;
  background-color: #111;
  width: 112px;
  height: 32px;
  line-height: 27px;
  font-size: 16px;
  padding: 0;
  padding-top: 2px;
  text-decoration: none;
  display: block;
  text-align: center;
  cursor: pointer;
  margin-left: 1em;
  margin-right: 0;
}
.a-quote:hover {
  border: 1px solid #ff6d0c;
  background-color: #ff6d0c;
  text-decoration: none;
  color: #fff;
}
.a-copy {
  float: left;
  transition: 0.1s ease-in;
  border-radius: 32px;
  background-color: #f4f4f4;
  color: #777 !important;
  width: 48px;
  height: 32px;
  line-height: 27px;
  font-size: 16px;
  padding: 0;
  padding-top: 2px;
  text-decoration: none;
  display: block;
  text-align: center;
  cursor: pointer;
  margin-right: 5px;
}
.a-copy:hover {
  background-color: #666;
  color: #fff !important;
}

.product img {
  max-width: 52px;
  max-height: 52px;
}
.orderlist_head {
  color: #111;
  padding: 0;
  padding-bottom: 15px;
  font-size: 24px;
  border-radius: 2px 2px 0 0;
  text-align: left;
}
.orderlist_head a {
  color: #000;
  text-decoration: none;
}
.orderlist-item {
  padding-top: 3px;
  padding-bottom: 3px;
}
.category_seperator {
  background-color: #dbecff;
  padding: 15px;
  text-align: left;
  color: #000;
  font-weight: bold;
  margin: 0 1px;
  border-top: 1px solid white;
}
.category_seperator i {
  color: #fff;
}
.bottom-menu {
  display: none;
}
.waitingOrder {
  max-height: calc(100% - 6em - 95px - 1rem);
  overflow: auto;
}
.waitingOrder .moretop {
  box-shadow: inset 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
}
.waitingOrder .moredown {
  box-shadow: inset 0px -10px 30px 0px rgba(0, 0, 0, 0.15);
}
.waitingOrder .moredown.moretop {
  box-shadow: inset 0px 10px 30px 0px rgba(0, 0, 0, 0.15),
    inset 0px -10px 30px 0px rgba(0, 0, 0, 0.15);
}
.waitingOrder div:last-child .prod {
  border: none !important;
}
.waitingOrder img {
  max-width: 46px;
  max-height: 58px;
}
.waitingOrder .photo {
  float: left;
  width: 52px;
  height: 56px;
  text-align: center;
}

.waitingOrder .image_placeholder {
  width: 46px;
  height: 40px;
  /* margin-top: 8px; */
  display: block;
  background-color: #f6f6f6;
}
.sidelist {
  max-height: calc(100% - 6em - 1px);
  overflow: auto;
}
.orderButtonContainer {
  padding: 15px 17px;
}
.prodimg {
  max-height: 200px;
  margin: auto;
  display: block;
  cursor: zoom-in;
}
.linkmore {
  border-radius: 2rem;
  font-size: 0.9em;
}
.blackmore {
  background-color: black;
  border-color: black;
}
a.btn.whitehover:hover {
  color: white;
}
.border-fat {
  border-width: 2px;
}
.mw250 {
  max-width: 250px;
}
.mw200 {
  max-width: 200px;
}
.mh100 {
  min-height: 100px;
}
.mh200 {
  min-height: 200px;
}
.mw120 {
  max-width: 120px;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  outline: none;
  box-shadow: none;
}
.btn-link:focus,
.btn-link.focus {
  border-right: 1px solid transparent;
  border-left: 1px solid #c1c3c6;
  border-top: 1px solid #c1c3c6;
  border-bottom: 1px solid #c1c3c6;
}
.carthover {
  background-color: #000;
  /*border-left: 1px solid #c1c3c6;*/
}
.cartheader {
  background-color: #f5f5f5 !important;
  height: calc(6em + 1px);
  border-bottom: 1px solid #c1c3c6;
  font-weight: bold;
}

.cartheader .far {
  font-size: 24px;
}
::-webkit-scrollbar {
  background: transparent;
}
table::-webkit-scrollbar {
  background: #999;
}
.clickable:hover {
  cursor: pointer;
}
#prodsub {
  position: fixed;
  left: 0px;
  height: 100%;
  width: 290px;
  background-color: #ececec;
  z-index: 11;
  box-shadow: 0px 0px 20px #c1c3c6;
  transform: translateX(calc(-100vw - 290px));
  transition: 0.4s;
}
#prodsub.showsub {
  transform: translateX(0);
}
#prodsub a {
  color: black;
  padding: 10px;
  /*font-size: 0.9em;*/
  border-bottom: 1px solid #c1c3c6;
  min-width: 290px;
  text-align: left;
  display: block;
  /* font-weight: bold; */
  /*text-transform: uppercase;*/
}
#prodsub a.all-products {
  color: #3f7cfc;
  background-color: #f5f5f5 !important;
  font-weight: normal !important;
}
.sublist {
  overflow: auto;
  height: 100%;
}
#prodsub a.closesub {
  position: absolute;
  right: -24px;
  top: 0;
  width: 24px;
  height: 100%;
  background-color: #007bff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: auto;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
}
#prodsub a.closesub:hover {
  text-decoration: none;
  background-color: #0d6fd7;
}
.closesub i.fas:before {
  content: "\F0D9";
}
.sidebar-left .nav-item.showsub {
  background-color: #ececec;
  border-left-color: #ececec;
}

.modal .product:hover {
  background-color: initial;
}
.modal .phototitle {
  font-size: 0.98rem;
}

.modal-body img {
  max-width: 100%;
}

.sublistFix {
  position: relative !important;
  overflow: hidden !important;
  width: 290px !important;
  height: calc(100% - (6em + 1px)) !important;
}
.box {
  overflow-y: auto !important;
  margin-bottom: 0 !important;
}
.fakelink {
  cursor: pointer;
  opacity: 0.75;
}
.fakelink:hover {
  opacity: 1;
}
.orderheader {
  background: #dbecff;
}
td.text-truncate {
  max-width: 20vw;
}
.ordertab {
  padding: 15px;
  display: block;
  border-bottom: 2px solid transparent;
  color: #000;
  font-weight: bold;
  white-space: nowrap;
  position: relative;
}
.ordertab:hover {
  border-bottom: 2px solid #ff6d0c;
  color: #ff6d0c;
  text-decoration: none;
}
.ordertab.active {
  border-bottom: 2px solid #0d6fd7;
  color: #0d6fd7;
}
.rowsfound {
  border-radius: 50%;
  background: red;
  width: 18px;
  height: 18px;
  position: absolute;
  display: flex;
  align-items: center;
  top: 9px;
  right: 9px;
  justify-content: center;
  color: #fff;
  padding: 3px;
  font-size: 10px;
}
.historyrow .fa-print {
  font-size: 20px;
}
.historyproduct {
  transition: 0.1s ease-in;
  text-align: left;
  clear: both;
  position: relative;
  overflow: hidden;
}

.historyproduct .cartprice {
  float: right;

  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  min-height: 64px;
}
.historyproduct .cart-buttons {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
}
.historyproduct .productweight {
  width: calc(100% + 32px);
  height: calc(100% + 32px);
  position: absolute;
  right: calc(-100% - 48px - 0.5rem);
  top: -16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #007bff;
  color: #fff;
  transition: all 0.1s ease;
}
.historyproduct .productweight.show {
  right: calc(-16px);
}
.historyproduct .productweight input[type="number"] {
  width: 100%;
  line-height: normal;
  border-radius: 6px;
  border: 1px solid #f1f1f1;
  text-align: right;
}
.max50 {
  max-width: 50px;
  max-height: 50px;
}
.equalize-prepend {
  width: 50px;
}
.daybadge {
  color: white;
  border-radius: 50%;
  padding: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.daybadge.ma {
  background-color: #fab500;
}
.daybadge.di {
  background-color: #ff8900;
}
.daybadge.wo {
  background-color: #df4954;
}
.daybadge.do {
  background-color: #dc30ad;
}
.daybadge.vr {
  background-color: #9f39d1;
}
.daybadge.za {
  background-color: #424bd2;
}
.daybadge.zo {
  background-color: #00ce86;
}
.table .image_placeholder {
  width: 52px;
  height: 46px;
  display: block;
  background-color: #f6f6f6;
}
.table .columnexpand {
  width: 99%;
}
.table-hover tbody tr:hover {
  background-color: #f7fbff;
}
.nowrap {
  white-space: nowrap;
}
.custom-select {
  background: url("./assets/Arrow-down.svg") no-repeat right 5px center/20px
    30px white;
}
tr:nth-child(odd) .table {
  background-color: #f6f6f6;
}
.custom-file {
  overflow: hidden;
  white-space: nowrap;
}
.custom-file-label::after {
  content: "Bladeren";
}
.partner .imglink {
  height: 80px;
  display: flex;
  align-items: center;
}
.partner .partnertextholder {
  height: 3.6em;
  overflow: hidden;
}
/* styles for '...' */
.partner .partnertext {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 3.6em;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  /* place for '...' */
  padding-right: 1em;
}
/* create the ... */
.partner .partnertext:before {
  /* points in the end */
  content: "...";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of block */
  right: 0;
  bottom: 0;
}
/* hide ... if we have text, which is less than or equal to max lines */
.partner .partnertext:after {
  /* points in the end */
  content: "";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
  background: white;
}
.stock {
  display: block;
  width: 50px;
  position: relative;
  height: 16px;
  border-radius: 15px;
  padding: 3px;
  float: right;
  background: #f1f1f1;
}
.stock:after {
  content: "";
  position: absolute;
  left: 3px;
  top: 3px;
  height: 10px;
  border-radius: 15px;
}
.stock.red:after {
  background-color: red;
  width: calc(33% - 6px);
}
.stock.orange:after {
  background-color: orange;
  width: calc(66% - 6px);
}
.stock.green:after {
  background-color: green;
  width: calc(100% - 6px);
}
@media (min-width: 992px) {
  .main {
    padding-left: 290px; /* 180 + 40 */
  }
  .sidebar-left {
    left: 0;
  }
}
@media (max-width: 991px) {
  .content {
    height: calc(100% - 6.1em) !important;
  }

  .main.cart {
    /*padding-right: 255px; /* 180 + 40 */
    padding-right: 0; /* 180 + 40 */
  }

  .content-bc {
    height: calc(100% - 8.8em) !important;
    max-width: 100vw;
  }

  .box {
    overflow-x: hidden !important;
  }
  .sidebar-left {
    /*height: calc(100% - 6.1em + 1px);
    top: 6.1em;*/
    height: calc(100% + 1px);
    width: 100vw;
    left: -100vw;
  }
  .sidebar-left.show {
    left: 0;
  }
  .sidebar-left .sidebar-logo {
    /*display: none;*/
    display: flex;
    justify-content: space-between;
  }
  .bottom-menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 80px;
    background: #ececec;
    display: flex;
    border-top: 2px solid #ececec;
    z-index: 1;
  }

  .bottom-menu a,
  .bottom-menu .dropup {
    flex-grow: 1;
    padding: 15px;
    max-width: 20%;
    font-size: 24px;
    position: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff6d0c;
    flex-direction: column;
    background: #ececec;
  }

  .bottom-menu a.active {
    background: #ff6d0c;
    color: white;
  }
  .bottom-menu a:hover {
    text-decoration: none;
  }
  .bottom-menu .dropdown-item {
    width: initial;
    max-width: 100%;
    border-bottom: 2px solid #ececec;
    background: #fff;
  }

  .bottom-menu .dropdown-item:last-child {
    border-bottom: none;
  }

  .bottom-menu a span {
    line-height: 0;
    font-size: 20px;
    margin-top: -5px;
  }

  .bottom-menu .dropdown-menu.dropdown-menu-right {
    padding: 0;
  }

  .bottom-menu .dropdown-menu {
    z-index: -1;
    max-height: calc(100% - 80px);
    overflow: auto;
    display: block;
    transform: translateY(100%);
    -webkit-animation: dropdown-down 0.1s ease;
    animation: dropdown-down 0.1s ease;
  }

  .bottom-menu .dropdown-menu.show {
    overflow: auto;
    margin-bottom: 0;
    transform: translateY(0);
    -webkit-animation: dropdown-up 0.1s ease;
    animation: dropdown-up 0.1s ease;
  }
  #prodsub {
    position: fixed;
    left: 0px;
    max-width: calc(100vw);
    max-height: calc(100% - 6.1em + 1px);
    top: calc(6.1em + 1px);
    height: 100%;
    background-color: #ececec;
    z-index: 999;
    box-shadow: 0px 0px 20px #c1c3c6;
    transform: translateX(-100vw);
    transition: 0.4s;
  }
  #prodsub.showsub {
    transform: translateX(0);
  }
  .closesub i.fas:before {
    content: "\F0D7";
  }
  .sublistFix {
    position: relative;
    overflow: hidden;
    width: calc(100vw - 24px) !important;
    height: calc(100% - 80px) !important;
  }
  .tabs {
    overflow-x: scroll;
  }
  .tabs::after {
    content: "";
    flex: 0 0 15px;
  }
  .right-shadow:after {
    position: fixed;
    content: "";
    right: 0;
    padding-top: 50%;
    padding-bottom: 50%;
    margin: auto;
    box-shadow: 0 0 5px 5px rgba(158, 158, 158, 0.8);
  }
  .left-shadow:before {
    position: fixed;
    content: "";
    left: 0;
    padding-top: 50%;
    padding-bottom: 50%;
    margin: auto;
    box-shadow: 0 0 5px 5px rgba(158, 158, 158, 0.8);
  }
  .mobileMenuToggle .far {
    width: calc(80px - 1.5rem);
    padding: 0;
  }
  .searchicon {
    /*height: 100%;*/
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .searchicon input::placeholder {
    color: transparent;
  }
}
@media (max-width: 768px) {
  .sidebar-right {
    max-width: 100%;
    right: -100vw;
  }
  #prodsub {
    width: 100% !important;
  }
  .main.cart {
    /*padding-right: 255px; /* 180 + 40 */
    padding-right: 0; /* 180 + 40 */
  }
  .searchicon .icon {
    left: 0;
    /* right: 0; */
  }
  .product .cartprice {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    line-height: normal;
  }
  .rowsfound {
    right: 0;
  }
}
@media (max-width: 528px) {
  .product .cartprice .cart-buttons {
    order: 1;
    margin-bottom: 16px;
    margin-top: 16px;
  }
  .col-search .col {
    width: calc(100vw - 82px - 160px);
    transition: all 0.1s ease;
  }
  .col-search.expand .col {
    width: calc(100vw - 82px);
  }
  .input-search {
    height: 100% !important;
  }
  .searchicon .icon .fa-times:before {
    color: #dc3545;
    content: "\F00D";
  }
  .bc-bar {
    display: none;
  }
}

@media screen and (max-width: 40em) {
  .responsiveTable .text-truncate {
    max-width: 100vw;
  }
  .responsiveTable .hidden {
    display: none;
  }
  .responsiveTable td .tdBefore {
    position: absolute;
    display: block;
    left: 1rem;
    width: 35%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left !important;
    font-weight: 600;
  }
  .responsiveTable td.pivoted {
    padding-left: 40% !important;
  }
  .responsiveTable tbody tr {
    border-color: #c1c3c6;
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes dropdown-up {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes dropdown-down {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}

.vertical-middle {
  vertical-align: middle !important;
}
.checkbox {
  padding-left: 20px;
}
.checkbox label {
  display: inline;
  position: relative;
  padding-left: 5px;
  height: 20px;
}
.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.checkbox label::after {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-top: 1px;
  margin-top: -1px;
  padding-left: 0px;
  font-size: 18px;
  line-height: normal;
  color: #555555;
}
.checkbox input[type="checkbox"] {
  opacity: 0;
}
.checkbox input[type="checkbox"]:checked + label::after {
  font-family: "Font Awesome 5 Pro";
  content: "\f00d";
}
.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}
.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}
.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #d9534f;
  border-color: #d9534f;
}
.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.maxw-50 {
  max-width: 50%;
}
.text-crimson {
  color: crimson;
}
.text-dimgrey {
  color: dimgrey;
}
.strikethrough {
  text-decoration: line-through;
}
